
import { arrayOf, shape, string } from 'vue-types';
import { customLinkArray } from '~/types/customLink';
import { isNullableString } from '~/types/isNullable';
import { visualList } from '~/types/visual';
import { layoutPropType } from '~/components/grids/CardGrid.vue';
import { images } from '~/types/image';
export default {
  inheritAttrs: false,
  props: {
    heading: isNullableString(),
    link: customLinkArray,
    illustration: images,
    recipes: arrayOf(
      shape({
        id: string(),
        title: string(),
        url: string(),
        label: isNullableString(),
        visual: visualList,
      }).loose,
    ),
    layout: layoutPropType,
  },
};
